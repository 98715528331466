<template>
  <div>贪吃蛇</div>
</template>

<script setup lang="ts">
</script>

<style scoped>

</style>
